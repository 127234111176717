<template>

  <div>
    <div class="row">
      <div class="col-md-12 flex justify-between">
        <div class="md:inline-block col-12 col-sm-6 flex">
          <vs-button
              :disabled="!perms"
              class="rounded-full text-white mr-2 py-3 px-4"
              color="primary"
              @click="activeDeleteDialog()"
          >
              <feather-icon icon="RefreshCwIcon" class="inline-block align-middle"/>
            {{ this.$t('Actualizar mis productos') }}
          </vs-button>
          <vs-button
            class="rounded-full text-white py-3 px-4"
            color="primary"
            @click="categoriesDialog = !categoriesDialog"
          >
            <feather-icon icon="PlusCircleIcon" class="inline-block align-middle mr-2"></feather-icon>
            {{ this.$t('Categorías') }}
          </vs-button>
        </div>
        <div class="md:inline-block col-12 col-sm-6" style="vertical-align: middle; margin-left: 25px !important;">
          <div class="col-1 inline-block">
            <span>{{ this.$t('Buscar') }}: </span>
          </div>
          <div class="col-4 inline-block ml-3" style="vertical-align: middle;">
            <div class="border-2 h-12 border-solid rounded-full" style="background-color:white; border-radius: 9px;" @click="$refs['users-search'].focus()">
                <div class="flex h-full">
                  <feather-icon icon="SearchIcon" style="width: 18px;" class="mt-2 mb-2 ml-2" />
                  <input
                      @keyup.enter="refreshProductsList()"
                      class="h-full w-full border border-none mr-5"
                      ref="users-search"
                      v-model="name"
                  />
                  <feather-icon @click="clearSearch()" v-show="name" icon="XCircleIcon" style="width: 18px; color: darkred;" class="mt-2 mb-2 mr-2" />
                </div>
            </div>
          </div>
          <div class="col-1 inline-block" style="margin-left: 10px !important;">
            <span>{{ this.$t('Ver') }}: </span>
          </div>
          <div class="col-3 inline-block ml-3" style="vertical-align: middle;">
            <div class="flex justify-end w-full">
              <!-- eslint-disable -->
              <vs-select v-model="maxItems" class="w-24 inline-block">
                <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
              </vs-select>
            </div>
            
          </div>
          <div class="col-2 inline-block" style="margin-left: 10px !important;">
            <vs-button
              :disabled="all.length == 0"
              style="background-color:#841910 !important; border-radius:10px"
              class=""
              @click="exportData"
            >
              <feather-icon icon="DownloadIcon" class="align-middle"></feather-icon>
            </vs-button>
          </div>
          
        </div>
        
      </div>
    </div>
    <div class="mt-10">

      <vs-table v-model="selected" :max-items="maxItems"  :data="products" :noDataText="this.$t( 'Datos no disponibles' ) ">

                <template slot="thead">
                  <vs-th style="max-width:60px;min-width: 60px;" class="text-info bgf">{{ this.$t('COD.') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('Nombre') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('SKU') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('Unidad') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('Tamaño') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('Stock') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('Fecha Act.') }}</vs-th>
                  <vs-th class="text-info bgf">{{ this.$t('Estado') }}</vs-th>
                  <vs-th class="text-info bgf"><!-- {{ this.$t('Acciones') }} --></vs-th>
                </template>

                <template slot-scope="{data}">
                  <template>
                    <div style="min-height:20px"></div>
                  </template>
                    <vs-tr  class="tr zoom" :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        
                        <!-- SKU -->
                        <vs-td style="max-width:60px;min-width: 60px;" :data="tr.code">
                            <span class="text-info"> {{ tr.code }} </span>
                        </vs-td>

                        <!-- nombre -->
                        <vs-td style="min-width:180px;" :data="tr.title">
                            <span>{{ tr.title }}</span>
                        </vs-td>


                        <!-- aqui va el resto-->
                        <vs-td :data="tr.sku">
                            <span> {{ tr.sku }} </span>
                        </vs-td>
                        <vs-td :data="tr.packing_unit">
                            <span> {{ tr.packing_unit }} </span>
                        </vs-td>
                        <vs-td :data="tr.packing_size">
                            <span> {{ tr.packing_size }} </span>
                        </vs-td>
                        <vs-td :data="tr.inventory">
                            <span> {{ tr.inventory }} </span>
                        </vs-td>
                        <vs-td :data="tr.last_update" style="width: 190px;">
                            <span> {{ tr.last_update }} </span>
                        </vs-td>
                        <vs-td :data="tr.status">
                          <div v-if="tr.status == 'A'"
                              style="background: #841910; color: #ffffff;"
                              class="w-24 p-1 mb-1 rounded-lg text-center"
                            >
                              {{ $t('Activo') }}
                          </div>
                          <div v-if="tr.status != 'A'"
                              style="background: #dee2e5; color: black;"
                              class="w-24 p-1 mb-1 rounded-lg text-center"
                          >
                              {{ $t('Inactivo') }}
                          </div>
                        </vs-td>
                        <!-- acciones -->
                        <vs-td :data="tr.id">
                          <div class="inline-flex">
                            <!-- editar -->
                              <vs-button color="transparent" @click="editProduct( tr.id )" style="padding: 0.5rem 0.5rem !important;">
                                <feather-icon icon="Edit2Icon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                              </vs-button>
                            </div>
                        </vs-td>
                    </vs-tr>
                  <div style="margin-top:20px; width:180px;"> {{ $t('mostrando') }} {{ products.length }} {{ $t('de') }} {{ pagination.total }}</div>
                </template>
              </vs-table>
            <vs-pagination :total="totalPages" class="mt-3" v-model="page"></vs-pagination>
        
        <!-- modal eliminar -->
        <vs-prompt
            @accept="toggleRoute()"
            @close="closeDeleteDialog()"
            :active.sync="activePrompt"
            :title="this.$t('Actualizar productos')"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click.native="closeDeleteDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require(`@/assets/images/question.png`)" alt="Icon" width="168px" />
            </div>
            
            <div class="text-center text-gray-400">
                
                <p>
                    <h5>{{ this.$t('¿Estás seguro que deseas ejecutar la actualizacion de productos?') }}</h5>
                    <span>{{ this.$t('Recuerda que esta accion puede generar inconvenientes en las compras') }}&nbsp;</span>
                </p>
                <p><vs-button style="border: #e32113 solid 2px" class="mt-5 rounded-full mr-5" @click="toggleRoute()">{{ this.$t('Ejecutar') }}</vs-button>
                <vs-button style="border: #e32113 solid 2px" class="mt-2 ml-5 rounded-full text-primary" @click.native="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
            </div>
        </vs-prompt>
        <!-- sidebar editar producto -->
        <sidebar-edit-product :sidebar="sidebar" :price="data_price" :data="activeProduct" @closeSidebar="closeSidebar()"></sidebar-edit-product>
        <modal-categories :dialog="categoriesDialog" @closeDialog="categoriesDialog = false" />
      </div>
    </div>
</template>

<script lang="ts">
import Vue                from 'vue';

import Cookies   from 'js-cookie';
import SidebarEditProduct from './SidebarEditProduct.vue';
import ModalCategories from './ModalCategories.vue';
export default Vue.extend({
    data() {
        return {
            categoriesDialog : false,
            all             : [],
            selected        : [],
            activePrompt    : false,
            editingProduct  : false,
            editing         : false,
            sidebar         : false,
            timeout         : true,
            activeProduct   : {
                title       : "",
                content     : "",
                category_id : "",
            },
            data_price: [],
            loading : false,
            maxItems        : 20,
            maxItemsVals    : [5,10,15,20,25,30,35,40,45,50],
            products        : [],
            page            : 1,
            name            : '',
            totalPages      : 1,
            newData: [],
            pagination   : { total: 1, current: 1, page_size: 20, name: '' },
            perms: false
        }
    },

    watch: {
        page () {
            window.scrollTo(0, 0);
            this.refreshProductsList();
        },
        maxItems () {
            this.page = 1;
            this.refreshProductsList();
        }
    },

    created() {
      const userInfo = JSON.parse(Cookies.get('userInfo'));
      if (userInfo.auth_grants.role != "USERS_SELLER"){
          this.perms = true
      }
      this.refreshProductsList();
    },

    methods: {
        async getAll(){
            const payload = {
                page : 1,
                page_size : 1000
            }
            this.loading = true;
            const res = await this.$store.dispatch('products/getAllProducts', payload);
            const products = res.results;
            
            this.loading = false;
            this.all = products
        },
        editProduct( id ) {
            let product = this.products.find(product => product.id === id);
            
            this.activeProduct = product;
            this.data_price = Object.keys(product.price)
            this.sidebar       = true;
        },
        closeDeleteDialog(){
          this.selected = [];
          this.activePrompt = false;
        },
        async toggleRoute() {
          this.loading = true;
          const res = await this.$store.dispatch('products/updateProductsForTaskService');
          this.loading = false;
          if (res) {
            this.activePrompt = false;
            this.$vs.notify({
              title: this.$t('El proceso ha comenzado a ejecutarse'),
              text: this.$t('Los productos e inventario se actualizarán y estarán disponibles en unos minutos'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            });
          } 
        }, //updateProductsForTaskService
        clearSearch(){
          this.name = '';
          this.refreshProductsList();
        },
        closeSidebar() {
            this.sidebar       = false;
            this.activeProduct = {
                title       : "",
                content     : "",
                category_id : "",
            };
            this.refreshProductsList();
        },

        async refreshProductsList() {
            const payload = {
                page : this.page,
                page_size : this.maxItems,
                name: this.name
            }
            this.loading = true;
            const res = await this.$store.dispatch('products/getAllProducts', payload);
            const products = res.results;
            await this.$store.dispatch('products/getDefaultProductImage');
            this.loading = false;
            
            const mapped = products.map((product) => {
                let ob = Object.assign({}, product);
                ob.edit = false;
                ob.collapsed = true;
                return ob;
            });
            this.pagination.total = res.total
            this.products = mapped;
            this.totalPages = res.total_pages;
            if (this.timeout){
              this.timeout = false;
              this.getAll();
            }
        },
        activeDeleteDialog() {
          if (this.perms){
            this.activePrompt = true;
          } else {
            this.$vs.notify({
                title: this.$t('No cuentas con los permisos sufucientes'),
                text: '',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning'
            });
            return;
          }
        },

        exportData() {
          let data = [];
          this.$vs.loading();
          if( this.all.length === 0 ) {
            this.$vs.notify({
                title: this.$t('No hay datos en esta consulta'),
                text: '',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning'
            });
            return;
          }

          data = this.mapData( this.all );

          const headerVal   = [
              "sku",
              "code",
              "name",
              "stock",
              "packing_unit",
              "packing_size",
              "content",
              "status",
              "last_update",
          ];
          const headerTitle = [
              this.$t("SKU"),
              this.$t("Codigo"),
              this.$t("Nombre"),
              this.$t("Inventario"),
              this.$t("Unidad"),
              this.$t("Tamaño"),
              this.$t("Descripcion"),
              this.$t("Estado"),
              this.$t("Fecha Act.")
          ];

          const cellAutoWidth = true;
          //const self = this;
          
          this.exportData = data;
          
          import('../../../vendor/Export2Excel').then( excel => {
            const list = this.exportData;
            this.$vs.loading.close();
        
            const data = this.formatJson( headerVal, list );

            excel.export_json_to_excel({
                header: headerTitle,
                data,
                filename: "lista do produtos",
                autoWidth: cellAutoWidth,
                bookType: "xlsx",
              });
                
            });
        },
        mapData( data=[] ) {            
          data.forEach( key => {
            let state = this.$t('Activo')
            if( key.status != 'A' ){ 
              state = this.$t('Inactivo');}
            this.newData.push({
              sku : key.sku,
              code : key.code,
              name : key.name,
              stock : key.inventory,
              packing_unit : key.packing_unit,
              packing_size : key.packing_size,
              status : state,
              content : key.content,
              last_update : key.last_update
            });
          });
          return this.newData;
        },

        formatJson( filterVal=[], jsonData=[] ) {
            return jsonData.map( v => filterVal.map( j => {
                return v[j];
            }) );
        },

    },

    computed: {

        categories() {
            return this.$store.state.categories.categories;
        },

        defaultImageUrl() {            
            return this.$store.state.products.defaultImageUrl;
        }
    },

    components: {
        SidebarEditProduct,
        ModalCategories,
    
    }
})
</script>

<style lang="scss" scoped>
.image-shadow {
  -webkit-box-shadow: 6px 9px 11px -1px rgba(0,0,0,0.16); 
  box-shadow: 6px 9px 11px -1px rgba(0,0,0,0.16);
}

.rounded-ful{
  border-radius: 15px !important;
  //background-color: white !important;
  border-width: 1px !important;
}

.vs-table-text{
  text-transform: uppercase;
}
/**.tr{
  border-bottom: #B9B9C3 solid 1px;
} */

.bgf{
  background-color: #F3F2F7!important;
  color: #636363 !important;
}

th.td-check {
  padding: 0!important;
  background-color: #F3F2F7!important;
}
.zoom:hover {
  //transform: scale(1.02) !important;
  //border:solid
}

.zoom {
  transition: transform .8s;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 13px !important;
}

</style>
