<template>
  <vs-sidebar click-not-close position-right parent="#app" class="hours-sidebar" spacer v-model="localActiveSidebar">
    <div class="mt-6 flex items-center justify-between px-6" slot="header">
      <h3 class="text-white">{{ this.$t('Editar producto') }}</h3>
      <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
    </div>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new px-5">
      <form @submit.prevent="saveProduct()" id="productForm">
        <div class="vx-row" style="margin: 0 1rem;">
          <!-- imagen -->
          <!-- <br>
          <div class="flex items-center">
            <h3 class="text-info">{{ this.$t('Información básica') }}</h3>
          </div>
          <vs-divider></vs-divider> -->
          <div class="vx-col w-full pl-16">
            <div class="flex justify-center">
              <img v-if="product.image" :src="product.image" :alt="product.title" class="rounded-lg h-48 w-48 object-contain"/>
              <img v-else :src="defaultImageUrl" :alt="product.image" class="rounded-lg h-48 w-48 object-contain"/>
            </div>
          </div>

          <!-- code -->
          <div class="pb-2 col-6 px-6" >
            <vs-input v-model="product.code" :label="this.$t('Código')" class="" disabled/>
          </div>

          <!-- sku -->
          <div class="pb-2 col-6 px-6" >
            <vs-input v-model="product.sku" :label="this.$t('SKU')" class="" disabled/>
          </div>

          <!-- nombre -->
          <div class="vx-col w-full px-6 w-1/2" >
            <vs-input v-model="product.title" :label="this.$t('Nombre')" class="w-full" :danger="!!validate.nameError" disabled/>
            <span v-if="!!validate.nameError" class="text-danger"> {{ validate.nameError }} </span>
          </div>

          <!-- code -->
          <div class="vx-col w-full px-6 mt-4" >
            <label lass="pb-2 pt-2 mx-2">(Poderá pesquisar e consultar o preço do produto de acordo com o seu grupo)</label>
          </div>
          <div class="col-6 px-6 mt-2" style="width:50%">
            <label for="categorie">{{ this.$t('Consulte grupo de precios') }}</label>
            <v-select
              :options="price"
              label="title"
              v-model="price_selected"
              class="selects"
              id="categorie"
            >
            </v-select>           
          </div>

          <!-- sku -->
          <div class="pb-2 pt-2 col-6 px-6" >
            <vs-input v-model="result_price" :label="this.$t('Precio')" class="" :danger="!!validate.nameError" disabled/>
          </div>

          <!-- categoría -->
          <div class="vx-col w-full px-6 mt-2" v-if="product.category">
            <label for="categorie">{{ this.$t('Categoría') }}</label>
                  
            <v-select
              :options="categories"
              label="title"
              :reduce="cat => cat.id"
              v-model="product.category[0].id"
              class="w-full"
              id="categorie"
              :disabled="!perms"
            > 
              <template slot="option" slot-scope="option">
                <span class="inline-block align-middle">{{ option.title }}</span>
              </template>

              <template slot="selected-option" slot-scope="option">
                <span class="inline-block align-middle">{{ option.title }}</span>
              </template>
            </v-select>
            <p class="text-danger text-sm">{{ errors.category_id }}</p>
            <!-- <span v-if="!!validate.nitError" class="text-danger"> {{ this.$t('Seleccione una categoría') }} </span>
            -->
          </div>

          <!-- formula médica -->
          <!-- <div class="vx-col w-full px-6 mt-5">
            <label for="form-med">{{ this.$t('Fórmula médica') }}</label>
            <vs-select v-model="product.med_form" id="evType" class="w-full">
              <vs-select-item v-bind:key="index" :value="item.id" :text="item.name" v-for="item,index in medForm"/>
            </vs-select>
          </div> -->

          <!-- estado -->
          <!-- <div class="vx-col w-full px-6 mt-5">
            <label for="status">{{ this.$t('Estado') }}</label>
            <div v-if="product.status == 'A'"
                style="background: #E32312; color: #ffffff;"
                class="w-24 p-1 mb-1 rounded-lg text-center"
              >
                Activo
            </div>
            <div v-if="product.status != 'A'"
                style="background: #dee2e5; color: black;"
                class="w-24 p-1 mb-1 rounded-lg text-center"
            >
                Inactivo
            </div>
          </div> -->

          <!-- descripción breve -->
          <div class="vx-col w-full px-6 mt-2">
            <label for="description">{{ this.$t('Descripción Breve') }}</label>
            <textarea :disabled="!perms" v-model="product.summary" id="description" class="w-full"></textarea>
            <span v-if="!!validate.descriptionError" class="text-danger"> {{ validate.descriptionError }} </span>
          </div>

          <!-- nueva Imagen -->
          <div class="vx-col w-full px-6 mt-2" v-if="perms">
            <label for="image">{{ this.$t('Subir nueva imagen') }}</label>

            <input :disabled="!perms" type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept="image/png, image/jpeg" @change="onChange()" />
                  
            <i class="block">{{ this.$t('Recuerda las características mínimas de la imagen de tu producto') }}</i>

            <div
              v-if="!image.file"
              class="border-2 border-dashed text-center pt-5 pb-8 mt-2 cursor-pointer"
              @click="clickFileButton()"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <img :src="require(`@/assets/icons/UploadFileIcon.svg`)" width="32px" class="inline-block align-middle"/><br/><br/>
              <span class="text-gray">{{ this.$t('Suelta tu imagen aquí, ó') }}</span>&nbsp;
              <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small">{{ this.$t('Busca Archivo') }}</vs-button>
            </div>

            <div v-if="!image.file" class="vx-row mt-2">
              <div class="vx-col w-3/5">
                <span class="text-gray">{{ this.$t('Formatos Soportados: JPG, PNG') }}</span>
              </div>

              <div class="vx-col w-2/5">
                <span class="text-gray float-right">{{ this.$t('Tamaño Máximo:') }} 5Mb</span>
              </div>
            </div>

            <vx-card v-else class="mt-2">
              <div class="vx-row">
                <div class="vx-col w-auto">
                  <img :src="require(`@/assets/icons/JpgFileIcon.svg`)" width="60px" class="inline-block align-middle"/><br/><br/>
                </div>

                <div class="vx-col w-11/12">
                  <feather-icon icon='XIcon' class="float-right cursor-pointer" @click="image.file = null" />
                  <h4>{{ image.file.name }}</h4>
                  <span>{{ this.$t('Subido') }} {{ image.progress }}%</span>
                  <vs-progress :height="12" :percent="image.progress" :color="image.error ? 'danger' : 'success'"></vs-progress>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </form>
    </VuePerfectScrollbar>
          
    <div class=" items-center p-6" slot="footer">
      <button type="button" class="vs-component vs-button vs-button-danger vs-button-border" @click="localActiveSidebar = false">
        {{ this.$t('Cancelar') }}
      </button>

      <button v-if="perms" type="submit" form="productForm" class="vs-component vs-button vs-button-info vs-button-filled float-right">
        {{ this.$t('Guardar') }}
      </button>
    </div>
  </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect             from 'vue-select';

import { Validation }      from '../../../plugins/validation.js';
import Cookies from 'js-cookie';
export default Vue.extend({

  props: {
    sidebar: { required: true, type: Boolean },
    data: { required: true },
    price: { required: true }
  },

  data() {
    return {
      validate     : new Validation(),
      medForm      : [
        { id: 0, name : 'No' },
        { id: 1, name : 'Sí' },
      ],
      validFormats : ['jpg', 'jpeg', 'png'],
      image        : {
        progress : 0,
        error    : '',
        file     : null,
        sku      : '',
      },
      errors : {
        category_id: '',
      },
      price_selected: '',
      result_price: '',
      perms: false
    }
  },

  watch: {
    'product.title'( val ) {
      this.validate.validateName( val );
    },
    'product.summary'( val ) {
      this.validate.validateDescription( val );
    },
    'price_selected'(val){
      this.result_price = this.product.price[val];      
    }
  },

  created() {
    const userInfo = JSON.parse(Cookies.get('userInfo'));
    if (userInfo.auth_grants.role != "USERS_SELLER"){
        this.perms = true
    }
  },
    
  methods: {
    prices(){
      this.price = Object.keys(this.product.price);
      this.price_selected = this.price[0]
      return this.price
    },
    saveProduct() {
      const isValidName = this.validate.validateName( this.product.title );
      const isValidDesc = this.validate.validateDescription( this.product.summary );
      
      if( !isValidName || !isValidDesc ) {
        this.$vs.notify({
          title: this.$t('Valide los campos antes de continuar'),
          text: '',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return;
      }

      if( this.image.file ) this.uploadImage();
      else this.uploadProduct();
    },

    uploadImage () {
      this.$vs.loading();
      this.$store.dispatch( 'products/uploadProductImageToFirebase', this.image )
        .then( res => {
          this.$vs.loading.close();
          this.product.image_url = res;
          this.uploadProduct();
        })
        .catch( () => {
          this.$vs.loading.close();
          this.$vs.notify({
            title    : this.$t('Error al subir imagen'),
            text     : this.$t('Intente de nuevo subir imagen'),
            iconPack : 'feather',
            icon     : 'icon-alert-circle',
            color    : 'warning'
          });
          return;
        });
    },

    uploadProduct() {
      if(!this.product.image_url ) this.product.image_url = this.product.image;
      this.product.category_id = this.product.category[0].id
      this.$vs.loading();
      this.$store.dispatch( 'products/uploadProduct', this.product )
        .then( res => {
          this.$vs.loading.close();
          if( res ) {
            this.localActiveSidebar = false;
            this.clearInputs();
          }
        });
    },

    onChange() {
      const filename = this.$refs.file.files[0].name;
      const filesize = this.$refs.file.files[0].size;
      const filesizeMb = Math.floor( filesize/1000000 );
      const ext = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();

      const isValidFormat = this.validFormats.filter(format => format === ext);
      
      if( filesizeMb > 2 ) {
        this.$vs.notify({
          title: `Seu arquivo tem um tamanho de ${filesizeMb} Mb`,
          text: this.$t(`El límite de tamaño es de 2Mb`),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 7000
        });
        return;
      }

      if( isValidFormat.length === 0 ) {                
        this.$vs.notify({
          title: this.$t('Formato Incorrecto'),
          text: this.$t('Cargue archivos .jpg ó .png solamente'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
        return;
      }
        
      this.image.file = this.$refs.file.files[0];
    },

    clickFileButton() {
      this.$refs.file.click();
    },

    dragover( ev ) {
      ev.preventDefault();
      if (!ev.currentTarget.classList.contains('bg-primary')) {
        ev.currentTarget.classList.remove('bg-gray-100');
        ev.currentTarget.classList.add('bg-primary');
      }
    },

    dragleave( ev ) {
      ev.currentTarget.classList.remove('bg-primary');
    },

    drop( ev ) {
      ev.preventDefault();

      this.$refs.file.files = ev.dataTransfer.files;
      this.onChange();
      ev.currentTarget.classList.remove('bg-primary');
    },

    clearInputs() {
      this.product.title       = '';
      this.product.category_id = 0;
      this.product.summary     = '';
      this.product.image       = '';
      this.product.content     = '';
      this.product.price_selected     = '';
      this.image.file          = null;            
    },
  },

  computed: {
    localActiveSidebar: {
      get: function() {
        return this.sidebar;
      },
      set: function() {                
        this.$emit( 'closeSidebar', true );
        //this.$store.dispatch( 'products/getAllProducts' );
      }
    },

    product : {
      get() {
        // category_id
        const product = this.data;
        if (!product.title) return product;
        product.category_id = product.category[0].id;
        return product;
      },
      set() {
        // this.product = {
        //   title       : "",
        //   content     : "",
        //   category_id : "",
        // }
      }
    },

    categories() { return this.$store.state.categories.categories; },
    defaultImageUrl() { return this.$store.state.products.defaultImageUrl; },
  },

  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  }
});
</script>

<style lang="scss" scoped>
.hours-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }
    
  ::v-deep .vs-sidebar {
    position: fixed;
    z-index: 52010;
    width: 550px;
    max-width: 90vw;
    .vs-sidebar--header {
      background: #841910;
      color: #FFF;
    }
  }
}

input{
  background-color: white !important;
    color: black;
    font-weight: 600 !important;
}
.scroll-area--data-list-add-new {
  height: 79vh !important;
}

textarea {
  height: 80px;
  line-height: 150%;
  resize:vertical;
  box-sizing: padding-box;
  font-size: 1rem;
  border-radius: 6px;
  box-shadow: 2px 2px 8px rgba(black, .3);
  border: 2;
}

label{
  font-size: .85rem;
}
</style>
