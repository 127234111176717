<template v-click-outside="outside">
    <vs-prompt
        :active.sync="activePrompt"
        buttons-hidden
        class="categories-modal sm:p-10 xs:p-0"
        @close="outside"
    >
        <div class="w-full">
            <h3 class="inline-block mt-3 text-info">{{ this.$t('Listado de categorías') }}</h3>

            <vs-button  class="float-right" @click.native="closeDialog()" color="transparent" text-color="#666">
                <feather-icon icon='XIcon'  />
            </vs-button>
        </div>
        
        <form @submit.prevent="addCategorie()" v-if="perms">
            <div class="vx-row mt-5">
                <div class="vx-col w-full mb-2">
                    <h4>{{ this.$t('Agregar Categoría') }}</h4>
                </div>

                <div class="vx-col sm:w-1/3 xs:w-full">
                    <vs-input v-model="categorie.title" :label="this.$t('Nombre')" class="w-full" autofocus required :disabled="editingCategorie"/>
                    <span v-if="!editingCategorie" class="text-danger"> {{ validate.nameError }} </span>
                </div>

                <div class="vx-col sm:w-1/3 xs:w-full">
                    <vs-input v-model="categorie.content" :label="this.$t('Descripción')" class="w-full" required :disabled="editingCategorie"/>
                    <span v-if="!editingCategorie" class="text-danger"> {{ validate.descriptionError }} </span>
                </div>

                <div class="vx-col sm:w-1/5 xs:w-full">
                    <button type="submit" class="vs-component vs-button rounded-full bg-info text-white vs-button-filled w-full mt-5" :disabled="editingCategorie">
                        {{ this.$t('Guardar') }}
                    </button>
                </div>

                <div class="vx-col pt-4" title="Refrescar categorías">
                    <vs-button @click="getAllCategories()" color="transparent" text-color="gray" class="float-right">
                        <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': loading }"></feather-icon>
                    </vs-button>
                </div>
            </div>
        </form>

        <vs-table :max-items="4" pagination search :data="categories" class="mt-5" :noDataText=" this.$t( 'Datos no disponibles' ) ">

            <template slot="thead">
                <vs-th>{{ this.$t('Nombre') }}</vs-th>
                <vs-th>{{ this.$t('Descripción') }}</vs-th>
                <!-- <vs-th>SLUG</vs-th> -->
                <vs-th></vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">

                    <!-- nombre -->
                    <vs-td :data="tr.title">
                        <vs-input
                            v-if="tr.edit"
                            v-model="tr.title" 
                            :danger="!!validate.nameError"
                            val-icon-danger="clear"
                            @keyup="validate.validateName(tr.title)"
                        />
                        <span v-else>{{ tr.title }}</span>
                        <p class="text-danger text-sm" v-if="tr.edit && !!validate.nameError">{{ validate.nameError }}</p>
                    </vs-td>

                    <!-- descripcion -->
                    <vs-td :data="tr.content">
                        <vs-input
                            v-if="tr.edit"
                            v-model="tr.content"
                            :danger="!!validate.desciptionError"
                            val-icon-danger="clear"
                            @keyup="validate.validateDescription(tr.content)"
                        />
                        <span v-else>{{ tr.content | truncate(80) }}...</span>
                        <p class="text-danger text-sm" v-if="tr.edit && !!validate.descriptionError">{{ validate.descriptionError }}</p>
                    </vs-td>

                    <!-- SLUG -->
                    <!-- <vs-td :data="tr.slug">
                        <vs-input
                            v-if="tr.edit"
                            v-model="tr.slug" 
                            :danger="!!validate.nameError"
                            val-icon-danger="clear"
                            @keyup="validate.validateName(tr.slug)"
                        />
                        <span v-else>{{ tr.slug }}</span>
                        <p class="text-danger text-sm" v-if="tr.edit && !!validate.nameError">{{ validate.nameError }}</p>
                    </vs-td> -->

                    <!-- acciones -->
                    <vs-td :data="tr.id">
                        <!-- boton editar -->
                        <vs-button size="small" color="transparent" @click="editCategorie( tr.id )" :disabled="!perms" v-if="perms">
                            <img v-if="tr.edit" :src="require(`@/assets/icons/EditBlueIcon.svg`)" width="15px" />
                            <img v-else :src="require(`@/assets/icons/EditGrayIcon.svg`)" width="15px" />
                        </vs-button>
                        
                        <!-- boton eliminar -->
                        <vs-button v-if="categorySelected.id !== tr.id && perms" size="small" color="transparent" @click.stop="activeDeleteDialog( tr.id )" :disabled="!perms">
                            <img :src="require(`@/assets/icons/DeleteGrayIcon.svg`)" width="15px" />
                        </vs-button>

                        <!-- boton eliminar confirmar -->
                        <vs-button v-if="categorySelected.id === tr.id" size="small" color="transparent" text-color="blue" @click.stop="deleteCategorie( tr.id )" :disabled="!perms">
                            <img :src="require(`@/assets/icons/DeleteBlueIcon.svg`)" width="15px" class="align-middle" />
                            confirmar
                        </vs-button>

                        <!-- boton guardar -->
                        <vs-button v-if="editingCategorie" color="transparent" @click="updateCategorie( tr.id )">
                            <img v-if="tr.edit" :src="require(`@/assets/icons/CheckGreenIcon.svg`)" width="15px" />
                            <img v-else :src="require(`@/assets/icons/CheckGrayIcon.svg`)" width="15px" />
                        </vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <!-- <vs-button class="rounded-full" @click="closeDialog()">
            Cerrar
        </vs-button> -->
    </vs-prompt>
</template>

<script>
import { Validation } from '../../../plugins/validation.js';
import Cookies from 'js-cookie';

export default {
    props: {
        dialog: {
            type     : Boolean,
            required : true,
        },
    },

    data() {
        return {
            categorie        : { title: '', content: '', slug: '', },
            validate         : new Validation(),
            editingCategorie : false,
            categorySelected : {},
            deleteDialog     : false,
            loading          : true,
            perms            : false,
        }
    },

    created() {
        const userInfo = JSON.parse(Cookies.get('userInfo'));
        if (userInfo.auth_grants.role != "USERS_SELLER"){
            this.perms = true
        }
        this.getAllCategories();
    },

    methods: {

        getAllCategories() {
            const promises = [
                this.$store.dispatch( 'categories/getAllCategories' ),
            ]

            this.loading = true;
            Promise.all( promises )
                .then( () => {
                    this.loading = false;
                });
        },

        closeDialog() {
            this.$emit( 'closeDialog', false );
        },

        addCategorie() {
            const isValidName = this.validate.validateName( this.categorie.title );
            const isValidDesc = this.validate.validateDescription( this.categorie.content );

            if( !isValidName || !isValidDesc ) {
                this.$vs.notify({
                    title: this.$t('Ingrese un nombre o una descripción válidas'),
                    text: '',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }

            this.categorie.slug = String( this.categorie.title ).toUpperCase().match(/[A-Z]/g).join('');

            this.$vs.loading();
            this.$store.dispatch( 'categories/createCategorie', this.categorie )
                .then( res => {
                    this.$vs.loading.close();
                    if( res ){
                        this.categorie.title = '';
                        this.categorie.content = '';
                        this.categorie.slug = '';
                    }
                });
        },

        editCategorie( catId ) {
            this.editingCategorie = true;
            const categorie = this.categories.find( cat => cat.id === catId );
            categorie.edit = true;
        },

        updateCategorie( catId ) {
            const category = this.categories.find( cat => cat.id === catId );
            const isValidName = this.validate.validateName( category.title );
            const isValidDesc = this.validate.validateDescription( category.content ); 
            const isValidSLUG = this.validate.validateDescription( category.slug );

            if( !isValidName || !isValidDesc || !isValidSLUG ) {
                this.$vs.notify({
                    title: this.$t('Ingrese un nombre o una descripción válida'),
                    text: '',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }

            this.$vs.loading();
            this.$store.dispatch( 'categories/updateCategorie', category )
                .then( res => {
                    this.$vs.loading.close();
                    if( res ) {
                        category.edit         = false;
                        this.editingCategorie = false;
                    }
                });
        },

        activeDeleteDialog( catId ) {
            const category = this.categories.find( cat => cat.id === catId );
            this.categorySelected = category;
        },

        deleteCategorie() {
            this.$vs.loading();
            this.$store.dispatch( 'categories/deleteCategorie', this.categorySelected )
                .then( res => {
                    this.$vs.loading.close();
                    if( res ) this.categorySelected = {};
                });
        },

        outside() {
            this.activePrompt = false;
        },
    },
    
    computed: {
        activePrompt: {
            get() {
                return this.dialog;
            },
            set( val ) {
                if( !val ) return;
                this.$store.dispatch( 'products/getAllProducts' );
                this.$emit( 'closeDialog', val );
            }
        },

        categories() {
            const categories = this.$store.state.categories.categories;
            const catsMapped = categories.map( cat => {
                const ob = Object.assign( {}, cat );

                ob.edit = false;

                return ob;
            });

            return catsMapped;
        },
    },

    directives: {
        'click-outside': {
            bind: function(el, binding) {
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler
                document.addEventListener('click', handler)
            },

            unbind: function(el) {
                document.removeEventListener('click', el.__vueClickOutside__)
                el.__vueClickOutside__ = null

            }
        }
    }
}
</script>

<style lang="scss">
.categories-modal {
    .vs-dialog {
        width: 80% !important;
        max-width: 100% !important;
        padding: 2rem;
    }
    
    .header-table {
        background-color: transparent !important;
    }
}

</style>
